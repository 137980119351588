import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                whiteText: '#FDFDFD',
                primaryText:'#252525', 
                secondaryText: '3F3F3F',
                primary: '#FDB468',
                secondary: '#FECD9A',
                third: '#FFE6CC',
                background: '#FDFDFD',
                secondaryBackground: '#E1E1E1',
                dark: '#252525',
                darkOnLight: '#252525',
                complementaryPrimary: '#258BB0',
                complementarySecondary: '#68D5FC',
            },
            dark: {
                whiteText: '#FDFDFD',
                primaryText:'#252525', 
                secondaryText: '3F3F3F',
                primary: '#FDB468',
                secondary: '#FECD9A',
                third: '#FFE6CC',
                background: '#252525',
                secondaryBackground: '#3F3F3F',
                dark: '#252525',
                darkOnLight: '#FDFDFD',
                complementaryPrimary: '#258BB0',
                complementarySecondary: '#68D5FC',

            }
        }
    }
});
