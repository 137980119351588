import Vue from 'vue'
import App from './views/App.vue'
import vuetify from './plugins/vuetify';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import "firebase/compat/analytics";
import 'firebase/compat/functions';
import 'firebase/compat/messaging';
import "firebase/compat/auth";
import router from './router'
import store from './store'

/// firebase init
firebase.initializeApp({
  apiKey: "AIzaSyAwH-brX8qMG7zR8NsHUxPZdWHvpuhT8sI",
  authDomain: "flightsearchfirebase.firebaseapp.com",
  databaseURL: "https://flightsearchfirebase.firebaseio.com",
  projectId: "flightsearchfirebase",
  storageBucket: "flightsearchfirebase.appspot.com",
  messagingSenderId: "400601488992",
  appId: "1:400601488992:web:7084df38e10d3c071bad05",
  measurementId: "G-WDVJ9PVKFS"
});

export const db = firebase.firestore();
export const firebaseAuth = firebase.auth();
export const firebaseMessaging = firebase.messaging.isSupported() ? firebase.messaging() : null
export const functions = firebase.functions();
export const functionsEuropa1= firebase.app().functions('europe-west1')
export const functionsStripe = firebase.app().functions('europe-west3')

// alias
Vue.prototype.$analytics = firebase.analytics()

navigator.serviceWorker.register('/firebase-messaging-sw.js')
  .then((registration) => {
    firebaseMessaging.useServiceWorker(registration)
  }).catch(err => {
    console.log(err)
  })
Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('formatTimestamp', function (value) {
  if (value) {
    const dt = new Date(value);
    return dt.getDate() + "." + (dt.getMonth() + 1) + "." + dt.getFullYear();
  }
  else return '';
});
Vue.filter('dateToMonthSymbol', function (value) {
  if (!value) return ''

  const date = new Date(value)
  let month = (date.getMonth() + 1)

  switch (month) {
    case 1:
      return "Jan"
    case 2:
      return "Feb"
    case 3:
      return "Mär"
    case 4:
      return "Apr"
    case 5:
      return "Mai"
    case 6:
      return "Jun"
    case 7:
      return "Jul"
    case 8:
      return "Aug"
    case 9:
      return "Sep"
    case 10:
      return "Okt"
    case 11:
      return "Nov"
    case 12:
      return "Dez"
    default:
      return null
  }

});
Vue.filter('isEmpty', function (value) {
  return Object.keys(value).length === 0;
});
Vue.filter('formattedLocation', function (value) {
  if (!value) return ''
  return value.ORT + " " + "(" + value.IATA + ")"
});
Vue.filter('priceTag', function (value){
  if (!value) return ''
  if(value == 'cheap') {
    return '$'
  }
  if(value == 'comfort') {
    return '$$'
  }
  if(value == 'luxury') {
    return '$$$'
  }
})

Vue.config.productionTip = false

/// Firebase Auth state was changed
let app = '';
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }
  if (user) {
    user.getIdTokenResult(true).then(idTokenResult => {
      user.isPremium = idTokenResult.claims.stripeRole == 'premium'
      store.dispatch("updateUser", user);
    })
  }
});



