import Vue from 'vue'
import Vuex from 'vuex'
/// Fetch Entry from Database
import { db } from "@/main";
import { firebaseAuth } from "@/main";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      agreedToPrivacy: false,
      darkMode: false,
      data: null
    },
    customer: null
  },
  getters: {
    user(state) {
      return state.user
    },
    customer(state) {
      return state.customer
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_DATA_PRIVACY(state, value) {
      state.user.agreedToPrivacy = value
    },
    SET_DARK_MODE(state, value) {
      state.user.darkMode = value
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
    SET_DISPLAY_NAME(state, value) {
      state.user.data.displayName = value
    },
    SET_CUSTOMER(state, value) {
      state.customer = value
    }

  },
  actions: {
    updateUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      ///Check the user object
      if (user) {
        /// commit darkMode
        var isDark = false
        if (localStorage['darkMode'] !== undefined) {
          isDark = JSON.parse(localStorage['darkMode'])
        }
        commit("SET_DARK_MODE", isDark);

        var agreedToPrivacy = false
        /// Check the local Storage use undefined check -> could be deleted 
        if (localStorage['agreedToPrivacy'] !== undefined) {
          agreedToPrivacy = JSON.parse(localStorage['agreedToPrivacy'])
        }
        commit("SET_DATA_PRIVACY", agreedToPrivacy);
        /// SET User.data 
        commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
          uid: user.uid,
          isPremium: user.isPremium,
          emailVerified: user.emailVerified,
        });

      } else {
        commit("SET_USER", null);
      }
    },
    updateDisplayName( {commit}, displayName) {
      /// validation could be implemented here
      console.log("UPDATE_DISPLAY_NAME", displayName)
      commit("SET_DISPLAY_NAME", displayName)
    },
    updateDarkMode({ commit }, user) {
      /// commit darkMode
      if (user) {
        var isDark = false
        if (localStorage['darkMode'] !== undefined) {
          isDark = JSON.parse(localStorage['darkMode'])
        }
        commit("SET_DARK_MODE", isDark);
      }

    },
    updateDataPrivacy({ commit }, user) {
      /// commit darkMode
      if (user) {
        var agreedToPrivacy = false
        /// Check the local Storage use undefined check -> could be deleted 
        if (localStorage['agreedToPrivacy'] !== undefined) {
          agreedToPrivacy = JSON.parse(localStorage['agreedToPrivacy'])
        }
        commit("SET_DATA_PRIVACY", agreedToPrivacy);
      }

    },
    async fetchCustomer({ commit }) {
      var user = firebaseAuth.currentUser;
      const snapshot = await db.collection('customers').doc(user.uid).get()
      const customer = snapshot.data();
      commit("SET_CUSTOMER", customer)
    }
  },
  modules: {
  }
})
