<template>
  <v-app v-if="isMaintenance" class="App">
    <v-main>

      <v-row justify="center" class="mt-8 pa-2" align="center">
      <v-col cols="6" class="mt-8">
        <h1 class="Background--text font-weight-light display text-uppercase text-center">Gateclosed bekommt ein Update</h1>
        <v-progress-linear class="mt-2 mb-4" color="primary" value="10" height="1" buffer-value="0"></v-progress-linear>
      </v-col>
       <lottie-animation
      class="mt-n16"
      path="animation/maintenance.json"
      :loop="true"
      :height="400"
      :width="1200"
      />
    </v-row>
    </v-main>
    
  </v-app>
  <v-app v-else class="App">
    <AppNavbar />
    <v-main class="overflow-hidden">
      <AppBanner v-bind:banner="banner"/>
      <Notification
        v-if="notification.show"
        v-bind:notificationId="notification.id"
        v-bind:showNotification="notification.show"
        v-bind:notificationTitle="notification.title"
        v-bind:notificationBody="notification.body"
        v-on:notificationClicked="openDetails($event)"
      />
      <router-view></router-view>
    </v-main>
    <DataPrivacy />
    <AppFooter />
  </v-app>
</template>

<script>
// router level code-splitting
const LottieAnimation =  () => import(/*webpackChunkName: "lottie" */ "lottie-vuejs/src/LottieAnimation.vue")
const AppNavbar =  () => import(/*webpackChunkName: "navbar" */ "../components/AppNavbar")
const AppBanner =  () => import(/*webpackChunkName: "banner" */ "../components/AppBanner")
const AppFooter =  () => import(/*webpackChunkName: "footer" */ "../components/AppFooter")
const DataPrivacy =  () => import(/*webpackChunkName: "data" */ "../components/AppDataPrivacy")
const Notification =  () => import(/*webpackChunkName: "notification" */ "../components/Notification")
import { firebaseMessaging } from "@/main";
import { db } from "@/main";

export default {
  name: "App",
  components: {
    LottieAnimation,
    AppNavbar,
    AppBanner,
    DataPrivacy,
    AppFooter,
    Notification
  },
  data: () => ({
    notification: {
      id: null,
      show: false,
      title: null,
      body: null
    },
    banner: {
      isActive: false,
      icon: null,
      title: null,
      subtitle: null
    },
    isMaintenance: false
  }),
  methods: {
    openDetails(id) {
      this.notification.show = false
      this.$router.push({
            name: "dealDetails",
            params: { dealId: id}
        });
    },
    async getWarning() {
      let banner = await db.collection("WARNINGS").doc("AppWarning").get();
      /// WarningObject - Maintanence 
      this.banner = banner.data();
      this.isMaintenance  = banner.data().isMaintenance
    }
  },
  created() {
    this.getWarning()
    /// Safari does not support push notifications at the moment
    if (firebaseMessaging != null) {
      firebaseMessaging.onMessage((payload) => {
        this.notification.id = payload.data.id
        this.notification.title = payload.data.title
        this.notification.body = payload.data.body
        this.notification.show = this.notification.id && this.notification.body ? true : false
      });
    }

    if (localStorage["darkMode"] !== undefined) {
      let isDark = JSON.parse(localStorage["darkMode"]);
      this.$vuetify.theme.dark = isDark;
    } else {
      this.$vuetify.theme.dark = false;
    }
  },
};
</script>
