import Vue from 'vue'
import VueRouter from 'vue-router'
import {firebaseAuth} from '@/main'

Vue.use(VueRouter)

const routes = [
/* Admin Space */
  {
    path: '/admin/pending_flights',
    name: 'admin pending',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "pending" */ "../views/AdminSpace/Pending.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/deal/:dealId',
    name: 'dealDetails',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "details" */ "../views/DealDetails.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/geschaeftsbedingungen',
    name: 'geschäftsbedingungen',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "terms" */ "../views/Terms.vue"),
  },
  {
    path: '/impressum',
    name: 'imprint',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "imprint" */ "../views/Imprint.vue"),
  },
  {
    path: '/faq',
    name: 'faq',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "faq" */ "../views/FAQ.vue"),
  },
  {
    path: '/landing',
    name: 'landing',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "landing" */ "../views/Landing.vue"),
  },
  {
    path: '/betasearch',
    name: 'betasearch',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "membersearch" */ "../views/MemberSearch.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: '/register',
    name: 'register',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "register" */ "../views/Register.vue"),
  },
  {
    path: '/reset',
    name: 'reset',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "reset" */ "../views/ResetPassword.vue"),
  },
  {
    path: '/member',
    name: 'member',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "member" */ "../views/Member.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/premium',
    name: 'premium',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "premium" */ "../views/Premium.vue"),
  },
  {
    path: '/premium/paymentsuccess',
    alias:'/landing/premium/paymentsuccess',
    name: 'PaymentSuccess',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "paymentSuccess" */ "../views/PaymentSuccess.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/premium/paymentfailure',
    alias:'/landing/premium/paymentfailure',
    name: 'PaymentFailure',
    // router level code-splitting
    component: () => import(/*webpackChunkName: "paymentFailure" */ "../views/PaymentFailure.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // and finally the default route, when none of the above matches:
  { path: "*",
    // router level code-splitting
    component: () => import(/*webpackChunkName: "landing" */ "../views/Landing.vue"),
  },
  /// external links
  {
    path: "/facebook",
    beforeEnter() {
      window.open("https://www.facebook.com/gateclosed.de/", "_blank");
    }
  },
  {
    path: "/instagram",
    beforeEnter() {
      window.open("https://www.instagram.com/gateclosed.de/", "_blank");
    }
  }

]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(route => route.meta.requiresAuth)

  const user = await firebaseAuth.currentUser
  //const requiresPremium = to.matched.some(route => route.meta.requiresPremium)

  /// Existing user and to is login => dashboard
  if (user && to.name === 'login') {
    next('/dashboard')
  }

  /// No user and is Auth required ==> landing
  if (requiresAuth && !user) {
    next('/landing')
  } 
  else {
    next()
  }

})

export default router
//rerun deployment